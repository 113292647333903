/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

const SERVICES_ROUTE = 'validators'
const FLEX_ROUTE = 'flex-staking'
const JSTAKING_ROUTE = 'jstaking'

export function useApp() {
  const route = useRoute()
  const isServices = computed(() => route.path.includes(SERVICES_ROUTE))
  const isFlexStaking = computed(() => route.path.includes(FLEX_ROUTE))
  const isJstaking = computed(() => route.path.includes(JSTAKING_ROUTE))
  const isClient = computed(() => typeof document !== 'undefined')

  const validatorBasePath = computed(() => {
    if (isJstaking.value) {
      return JSTAKING_ROUTE
    }
    if (isFlexStaking.value) {
      return FLEX_ROUTE
    }
    return SERVICES_ROUTE
  })

  return {
    isClient,
    validatorBasePath,
    isServices,
    isJstaking,
    isFlexStaking,
  }
}
