/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

import type {
  Blockhash,
  Commitment,
  Connection,
  FetchMiddleware,
  PublicKey,
  Signer,
  TransactionInstruction,
} from '@solana/web3.js'
import { WalletNotConnectedError } from '@solana/wallet-adapter-base'
import {
  ComputeBudgetProgram,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js'
import { base64Encode } from './common'

/**
 * Generic Wallet interface to support any wallet implementation.
 */
type Wallet = {
  publicKey: PublicKey | null
  signTransaction: (transaction: VersionedTransaction) => Promise<VersionedTransaction>
  signAllTransactions?: (transactions: VersionedTransaction[]) => Promise<VersionedTransaction[]>
}

/**
 * Sends and signs a single transaction.
 */
export async function sendTransaction(
  connection: Connection,
  wallet: Wallet,
  instructions: TransactionInstruction[],
  signers: Signer[] = [],
  options: {
    priorityFee?: number
    commitment?: Commitment
  } = {},
): Promise<string> {
  const { priorityFee = 0, commitment = 'confirmed' } = options

  if (!wallet.publicKey) {
    throw new WalletNotConnectedError()
  }

  const { blockhash } = await connection.getLatestBlockhash(commitment)

  const txInstructions = priorityFee > 0
    ? [ComputeBudgetProgram.setComputeUnitPrice({ microLamports: priorityFee }), ...instructions]
    : instructions

  const messageV0 = new TransactionMessage({
    payerKey: wallet.publicKey,
    recentBlockhash: blockhash,
    instructions: txInstructions,
  }).compileToV0Message()

  const transaction = new VersionedTransaction(messageV0)

  if (signers.length > 0) {
    transaction.sign(signers)
  }

  const signedTransaction = await wallet.signTransaction(transaction)
  const rawTransaction = signedTransaction.serialize()

  const txId = await connection.sendRawTransaction(rawTransaction, {
    skipPreflight: true,
    maxRetries: 3,
  })

  console.log('TX Signature:', txId)
  console.log('TX Raw:', base64Encode(rawTransaction))

  return txId
}

type SendTransactionsParams = {
  commitment?: Commitment
  onSuccess?: (txId: string, idx: number) => Promise<void> | void
  onError?: (error: any, idx: number) => Promise<boolean> | boolean
  blockhash?: Blockhash
  maxRetries?: number
  stopOnError?: boolean
  priorityFee?: number
  separate?: boolean
}

/**
 * Sends and signs multiple transactions.
 */
export async function sendTransactions(
  connection: Connection,
  wallet: Wallet,
  instructionSets: TransactionInstruction[][],
  signerSets: Signer[][],
  params: SendTransactionsParams = {},
): Promise<string[]> {
  const {
    commitment = 'confirmed',
    maxRetries = 3,
    onSuccess,
    onError,
    stopOnError = false,
    blockhash,
    priorityFee = 0,
  } = params

  if (!wallet.publicKey) {
    throw new WalletNotConnectedError()
  }

  const recentBlockhash = blockhash || (await connection.getLatestBlockhash(commitment)).blockhash

  const transactions: VersionedTransaction[] = []

  for (let i = 0; i < instructionSets.length; i++) {
    const instructions = instructionSets[i]
    if (!instructions || instructions.length === 0) {
      continue
    }

    const txInstructions = priorityFee > 0
      ? [ComputeBudgetProgram.setComputeUnitPrice({ microLamports: priorityFee }), ...instructions]
      : instructions

    const messageV0 = new TransactionMessage({
      payerKey: wallet.publicKey,
      recentBlockhash,
      instructions: txInstructions,
    }).compileToV0Message()

    const transaction = new VersionedTransaction(messageV0)
    const signers = signerSets[i] || []
    if (signers.length > 0) {
      transaction.sign(signers)
    }
    transactions.push(transaction)
  }

  console.log('Sending Transactions:', transactions.length)

  const signedTransactions = !params.separate && wallet.signAllTransactions
    ? await wallet.signAllTransactions(transactions)
    : await Promise.all(transactions.map(tx => wallet.signTransaction(tx)))

  const results: string[] = []

  for (let i = 0; i < signedTransactions.length; i++) {
    const signedTransaction = signedTransactions[i]
    const rawTransaction = signedTransaction!.serialize()

    console.log(`TX(#${i}) Raw:`, base64Encode(rawTransaction))

    try {
      const txId = await connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
        maxRetries,
      })

      console.log(`TX(#${i}) Signature:`, txId)

      if (onSuccess) {
        await onSuccess(txId, i)
      }

      results.push(txId)
    } catch (error) {
      console.log(`TX(#${i}) Error:`, error)

      let shouldContinue = true
      if (onError) {
        shouldContinue = await onError(error, i)
      }

      results.push('')

      if (stopOnError && !shouldContinue) {
        break
      }
    }
  }

  return results
}

type ITokenStorage = {
  setToken: (token: string) => void
  getToken: () => string | null
  getTimeSinceLastSet: () => number | null
}

export class LocalTokenStorage implements ITokenStorage {
  private tokenKey = 'auth-token'
  private timeKey = 'last-set'

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token)
    localStorage.setItem(this.timeKey, String(Date.now()))
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey)
  }

  getTimeSinceLastSet(): number | null {
    const lastSet = localStorage.getItem(this.timeKey)
    return lastSet ? Date.now() - Number(lastSet) : null
  }
}

type TokenAuthFetchMiddlewareArgs = {
  tokenStorage?: ITokenStorage
  tokenExpiry?: number
  getToken: () => Promise<string>
}

/**
 * Middleware to handle token-based authentication in fetch requests.
 */
export function tokenAuthFetchMiddleware({
  tokenStorage = new LocalTokenStorage(),
  tokenExpiry = 5 * 60 * 1000, // 5 minutes
  getToken,
}: TokenAuthFetchMiddlewareArgs): FetchMiddleware {
  return (url: string, options: any, fetch: (...args: any) => void) => {
    (async () => {
      try {
        const token = tokenStorage.getToken()
        const timeSinceLastSet = tokenStorage.getTimeSinceLastSet()
        const tokenIsValid
          = token && token !== 'undefined' && timeSinceLastSet && timeSinceLastSet < tokenExpiry - 10000
        if (!tokenIsValid) {
          const newToken = await getToken()
          if (newToken) {
            tokenStorage.setToken(newToken)
          }
        }
      } catch (e: any) {
        console.error(e)
      }
      fetch(url, {
        ...options,
        headers: {
          ...(options || {}).headers,
          Authorization: `Bearer ${tokenStorage.getToken()}`,
        },
      })
    })()
  }
}
