import type {
  AddressLookupTableAccount,
  Connection,
  RecentPrioritizationFees,
  RpcResponseAndContext,
  SignatureResult,
  SimulatedTransactionResponse,
  TransactionInstruction } from '@solana/web3.js'
import {
  ComputeBudgetProgram,
  LAMPORTS_PER_SOL,
  PublicKey,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js'
import BN from 'bn.js'

const MAX_RECENT_PRIORITY_FEE_ACCOUNTS = 128

/**
 * Returns an estimate of a prioritization fee for a set of instructions.
 *
 * The estimate is based on the median fees of writable accounts that will be involved in the transaction.
 *
 * @param connection
 * @param ixs - the instructions that make up the transaction
 * @param basePriorityFee
 * @returns prioritizationFeeEstimate -- in microLamports
 */
export async function estimatePrioritizationFee(
  connection: Connection,
  ixs: TransactionInstruction[],
  basePriorityFee?: number,
): Promise<number> {
  const writableAccounts = ixs
    .flatMap(ix => ix.keys.filter(a => a.isWritable).map(k => k.pubkey))

  const uniqueWritableAccounts = [...new Set(writableAccounts.map(x => x.toBase58()))]
    .slice(0, MAX_RECENT_PRIORITY_FEE_ACCOUNTS)
    .map(a => new PublicKey(a))

  const priorityFees = await connection.getRecentPrioritizationFees({
    lockedWritableAccounts: uniqueWritableAccounts,
  })

  // If no priority fees are found, return the base priority fee or 1 as the default
  if (priorityFees.length === 0) {
    return Math.max(basePriorityFee ?? 0, 1)
  }

  const maxFeeBySlot = priorityFees.reduce((acc, fee) => {
    if (!acc[fee.slot] || fee.prioritizationFee > (acc[fee.slot]?.prioritizationFee ?? 0)) {
      acc[fee.slot] = fee
    }
    return acc
  }, {} as Record<number, RecentPrioritizationFees>)

  const sortedMaxFees = Object.values(maxFeeBySlot).sort((a, b) => a.slot - b.slot)

  // Get the median of the last 100 fees
  const recentFees = sortedMaxFees.slice(-100)
  if (recentFees.length === 0) {
    return Math.max(basePriorityFee ?? 1, 1)
  }

  const mid = Math.floor(recentFees.length / 2)
  const medianFee = recentFees.length % 2 !== 0
    ? recentFees[mid]?.prioritizationFee ?? 0
    : ((recentFees[mid - 1]?.prioritizationFee ?? 0) + (recentFees[mid]?.prioritizationFee ?? 0)) / 2

  return Math.max(basePriorityFee ?? 1, Math.ceil(medianFee))
}

/**
 * Function to allow only numbers and a single decimal point to be inputted.
 *
 * @param {any} e - event parameter
 */
export function onlyNumber(e: any) {
  const keyCode = e.keyCode ? e.keyCode : e.which
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    e.preventDefault()
  }
  if (keyCode === 46 && String(e.target.value).includes('.')) {
    e.preventDefault()
  }
}

function getErrorFromRPCResponse(rpcResponse: RpcResponseAndContext<
    SignatureResult | SimulatedTransactionResponse
>) {
  // Note: `confirmTransaction` does not throw an error if the confirmation does not succeed,
  // but rather a `TransactionError` object. so we handle that here
  // See https://solana-labs.github.io/solana-web3.js/classes/Connection.html#confirmTransaction.confirmTransaction-1

  const error = rpcResponse.value.err
  if (error) {
    // Can be a string or an object (literally just {}, no further typing is provided by the library)
    // https://github.com/solana-labs/solana-web3.js/blob/4436ba5189548fc3444a9f6efb51098272926945/packages/library-legacy/src/connection.ts#L2930
    // TODO: if still occurs in web3.js 2 (unlikely), fix it.
    if (typeof error === 'object') {
      const errorKeys = Object.keys(error)
      if (errorKeys.length === 1) {
        if (errorKeys[0] !== 'InstructionError') {
          throw new Error(`Unknown RPC error: ${error}`)
        }
        // @ts-expect-error due to missing typing information mentioned above.
        const instructionError = error.InstructionError
        // An instruction error is a custom program error and looks like:
        // [
        //   1,
        //   {
        //     "Custom": 1
        //   }
        // ]
        // See also https://solana.stackexchange.com/a/931/294
        throw new Error(
          `Error in transaction: instruction index ${instructionError[0]}, custom program error ${instructionError[1].Custom}`,
        )
      }
    }
    throw new Error(error.toString())
  }
}

export async function getSimulationComputeUnits(
  connection: Connection,
  instructions: Array<TransactionInstruction>,
  payer: PublicKey,
  lookupTables: Array<AddressLookupTableAccount> | [],
): Promise<number | null> {
  const testInstructions = [
    // Set an arbitrarily high number in simulation
    // so we can be sure the transaction will succeed
    // and get the real compute units used
    ComputeBudgetProgram.setComputeUnitLimit({ units: 1_400_000 }),
    ...instructions,
  ]

  const testTransaction = new VersionedTransaction(
    new TransactionMessage({
      instructions: testInstructions,
      payerKey: payer,
      // RecentBlockhash can by any public key during simulation
      // since 'replaceRecentBlockhash' is set to 'true' below
      recentBlockhash: PublicKey.default.toString(),
    }).compileToV0Message(lookupTables),
  )

  const rpcResponse = await connection.simulateTransaction(testTransaction, {
    replaceRecentBlockhash: true,
    sigVerify: false,
  })

  getErrorFromRPCResponse(rpcResponse)
  return rpcResponse.value.unitsConsumed || null
}

const SOL_DECIMALS = Math.log10(LAMPORTS_PER_SOL)

export function solToLamports(amount: number): number {
  if (Number.isNaN(amount)) {
    return Number(0)
  }
  return new BN(Number(amount).toFixed(SOL_DECIMALS).replace('.', '')).toNumber()
}
