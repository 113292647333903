import { setupLayouts } from 'virtual:generated-layouts'
import { ViteSSG } from 'vite-ssg'
import { routes } from 'vue-router/auto-routes'
import App from '~/App.vue'
import type { UserModule } from '~/types'

import '~/assets/scss/app.scss'

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  {
    strict: false,
    base: import.meta.env.BASE_URL,
    routes: setupLayouts(routes),
  },
  (ctx) => {
    Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))
  },
)
