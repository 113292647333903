export function useLoginApi() {
  const { axiosHcClient } = useHcApi()

  const _refreshToken = useStorage('refresh-token', '')
  const accessToken = useStorage('accessToken', '')

  const refreshToken = async () => {
    try {
      const props = { token: _refreshToken.value || '' }
      const res = await axiosHcClient.post('auth/refresh', props)
      _refreshToken.value = res.data.refreshToken
      accessToken.value = res.data.accessToken
      return res.data?.accessToken
    } catch (err) {
      console.log(err)
    }
  }

  const login = async () => {
    try {
      const { data } = await axiosHcClient.get('user/me')
      return data
    } catch (err) {
      console.log(err)
    }
  }

  return {
    login,
    refreshToken,
  }
}
