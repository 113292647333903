import { PublicKey } from '@solana/web3.js'
import { useWallet } from 'solana-wallets-vue'
import { clickElement } from './dom'

export function clickWalletButton() {
  clickElement('.app-header__wallet-btn')
}

export async function getWalletEphemeralSigners(amount = 1): Promise<PublicKey[]> {
  const { wallet } = useWallet()
  const signers = wallet.value?.adapter
    && 'standard' in wallet.value.adapter
    && 'fuse:getEphemeralSigners' in wallet.value.adapter.wallet.features
    && (
      await (wallet.value?.adapter.wallet.features['fuse:getEphemeralSigners'] as any).getEphemeralSigners(amount)
    )
  return signers ? signers.map((s: string) => new PublicKey(s)) : []
}

/**
 * Check if wallet is multisig
 */
export function isMultisigWallet() {
  const { wallet } = useWallet()
  // wallet.value?.adapter.name.includes('squads')
  return wallet.value?.adapter
    && 'standard' in wallet.value.adapter
    && 'fuse:getEphemeralSigners' in wallet.value.adapter.wallet.features
}
