export enum PriorityMode {
  MaxCap,
  ExactFee,
}

export enum PriorityLevel {
  Fast = 'fast',
  Turbo = 'turbo',
  Ultra = 'ultra',
}

export const BASE_PRIORITY_FEE = {
  [PriorityLevel.Fast]: 100_000,
  [PriorityLevel.Turbo]: 1_000_000,
  [PriorityLevel.Ultra]: 5_000_000,
}

export const MICRO_LAMPORTS_PER_LAMPORT = 1_000_000
export const DEFAULT_EXACT_FEE = 0.003
export const DEFAULT_COMPUTE_UNITS = 200_000
// the amount of CU that will be added to the calculated one to provide a margin
// export const ADDITIONAL_COMPUTE_UNITS = 5000
// the multiplier for estimated CU to provide a margin
export const MULTIPLIER_COMPUTE_UNITS = 1.2
