/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

import SolanaWallets from 'solana-wallets-vue'
import {
  AvanaWalletAdapter,
  BackpackWalletAdapter,
  BitpieWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  CoinhubWalletAdapter,
  HuobiWalletAdapter,
  HyperPayWalletAdapter,
  KrystalWalletAdapter,
  LedgerWalletAdapter,
  MagicEdenWalletAdapter,
  MathWalletAdapter,
  MoongateWalletAdapter2,
  NekoWalletAdapter,
  NufiWalletAdapter,
  OKXWalletAdapter,
  OntoWalletAdapter,
  PhantomWalletAdapter,
  SafePalWalletAdapter,
  SaifuWalletAdapter,
  SalmonWalletAdapter,
  SkyWalletAdapter,
  SolflareWalletAdapter,
  SolongWalletAdapter,
  SpotWalletAdapter,
  TipLinkWalletAdapter,
  TokenaryWalletAdapter,
  TokenPocketWalletAdapter,
  TorusWalletAdapter,
  TrezorWalletAdapter,
  TrustWalletAdapter,
  WalletConnectWalletAdapter,
  XDEFIWalletAdapter,
} from 'wallets'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  const walletConnectAdapter = new WalletConnectWalletAdapter()
  walletConnectAdapter.setWalletConnectConfigGetter(() => {
    const con = useConnectionStore()
    return {
      providerOpts: {
        metadata: {
          name: 'Jpool',
          description: 'Jpool Web3 Wallet',
          url: 'https://app.jpool.one',
          icons: ['https://avatars.githubusercontent.com/u/37784886'],
        },
      },
      network: con.cluster === 'mainnet-beta' ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet,
      rpcEndpoint: con.connection.rpcEndpoint,
    }
  })

  app.use(SolanaWallets as any, {
    wallets: [
      new MoongateWalletAdapter2({
        authMode: 'Ethereum',
        position: 'top-right',
      }),
      new MoongateWalletAdapter2({
        authMode: 'Google',
        position: 'top-right',
      }),
      new MoongateWalletAdapter2({
        authMode: 'Apple',
        position: 'top-right',
      }),
      new TipLinkWalletAdapter({
        clientId: import.meta.env.TIP_WALLET_KEY ?? '',
        title: 'JPool',
        theme: 'system',
      }),
      new PhantomWalletAdapter(),
      new BackpackWalletAdapter(),
      new OKXWalletAdapter(),
      new TrustWalletAdapter(),
      new SolflareWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new SolongWalletAdapter(),
      new CloverWalletAdapter(),
      new BitpieWalletAdapter(),
      new Coin98WalletAdapter(),
      new CoinhubWalletAdapter(),
      new SafePalWalletAdapter(),
      new TokenPocketWalletAdapter(),
      new MathWalletAdapter(),
      new OntoWalletAdapter(),
      new LedgerWalletAdapter(),
      new HyperPayWalletAdapter(),
      new SkyWalletAdapter(),
      new SaifuWalletAdapter(),
      new MagicEdenWalletAdapter(),
      new NufiWalletAdapter(),
      new NekoWalletAdapter(),
      new SpotWalletAdapter(),
      new AvanaWalletAdapter(),
      new KrystalWalletAdapter(),
      new HuobiWalletAdapter(),
      new SalmonWalletAdapter(),
      new XDEFIWalletAdapter(),
      new TorusWalletAdapter(),
      new TokenaryWalletAdapter(),
      new TrezorWalletAdapter(),
      walletConnectAdapter,
    ],
    autoConnect: true,
  })
}
