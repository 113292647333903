<script lang="ts" setup>
import i18next from 'i18next'

const route = useRoute()

const i18nReady = ref(false)

const appUrl = import.meta.env.MODE === 'production' ? 'https://app.jpool.one' : 'https://dev.jpool.one'
const originUrl = `${appUrl}${route.fullPath}`

useHead({
  title: import.meta.env.VITE_APP_TITLE,
  titleTemplate: title => `${title} | JPool`,
  meta: [
    { name: 'description', content: import.meta.env.VITE_APP_DESCRIPTION },
    { name: 'keywords', content: import.meta.env.VITE_APP_KEYWORDS },
    { property: 'og:title', content: 'JPool' },
    { property: 'og:description', content: import.meta.env.VITE_APP_DESCRIPTION },
    { property: 'og:type', content: 'website' },
    { property: 'og:image', content: `${appUrl}/img/logo.svg` },
    { property: 'og:url', content: originUrl },
    { name: 'twitter:title', content: import.meta.env.VITE_APP_TITLE },
    { name: 'twitter:creator', content: '@JPoolSolana' },
  ],
})

const auth = useAuthStore()
const isClient = !import.meta.env.SSR
const isPasswordProtected = computed(() => auth.isEnabled && !auth.isAuthenticated && isClient)

const { initStorageVersion } = useStorageVersion()

function initI18Next() {
  if (i18next.resolvedLanguage !== 'en') {
    setTimeout(() => i18nReady.value = true, 500)
  } else {
    i18nReady.value = true
  }
}

onBeforeMount(() => {
  initHcApi()
  initWallet()
  initStorageVersion()

  if (i18next.isInitialized) {
    initI18Next()
  } else {
    i18next.on('initialized', () => {
      initI18Next()
    })
  }
})
</script>

<template>
  <password-protect v-if="isPasswordProtected" :class="{ 'app-ready': i18nReady }" />
  <router-view v-else :class="{ 'app-ready': i18nReady }" />
</template>

<style lang="scss" scoped>
.q-layout {
  opacity: 0;
}
.app-ready {
  opacity: 1;
  transition: opacity 0.5s !important;
}
</style>
